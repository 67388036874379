// eslint-disable-next-line import/no-anonymous-default-export
export default {
    account: {
        title: 'My Account',
        confirmDeleteAccount: 'Are you sure you want to delete this information?',
        profile: 'My information',
        orders: 'My orders',
        returns: 'Returns',
        userInfo: 'User Details',
        addresses: 'Addresses',
        help: 'Help',
        logout: 'Logout',
        newHere: "I'm new here",
        freeAccount: 'Free user account',
        save: 'Save',
        delete: 'Delete',
        edit: 'Edit',
        choose: 'Choose',
        change: 'Change',
        show: 'Zeigen',
        cancel: 'Cancel',
        more: 'More',
        viewMyProfile: 'View my profile',
        viewMyOrders: 'View my orders',
        signUp: 'Sign Up',
        requestResetPassword: 'Mail me the recovery link',
        welcome: 'Welcome ',
        placedOrders: 'Orders',
        latestDetails: 'Here you can view your latest orders and change your account details.',
        accountDetails: 'Addresses',
        accountProfile: 'My Account',
        changePassword: 'Change password',
        inOrder: 'In order to ',
        protect: 'protect your account, ',
        passwordContain: 'your password must contain',
        characters: 'At least 6 characters',
        upperCase: 'One uppercase letter',
        number: 'One number',
        specialCharacter: 'One special character (! # % +)',
        repeatEmail: 'Repeat mail address',
        editDetails: 'Edit Details',
        order: 'Order',
        trackingNumber: 'Tracking number: ',
        quantity: 'Quantity: ',
        viewOrder: 'View order',
        noOrder: 'Order cannot be displayed',
        totalAmountOrder: 'Total amount: ',
        form: {
            emailLabel: 'Your E-mail address',
            passwordLabel: 'Password*',
            minimumLength: 'Must be at least 6 characters',
            dataProtection: 'I would like to register for future orders and ask for my data to be included in your customer database. I can revoke this consent at any time with effect for the future. <a href="/datenschutz">Data protection</a>',
            dataEmail: 'In addition to processing the contract, we use your e-mail address to inform you by e-mail about our own similar goods/services. You can object to the sending of the e-mails at any time without incurring any costs other than the transmission costs according to the basic tariffs. <a href="/datenschutz">Data protection</a>',
        },
        address: {
            newAddress: 'Add new address',
            residential: 'Residential address',
            standardBilling: 'Standard billing address',
            standardDelivery: 'Standard delivery address',
            standardShipping: 'Standard shipping address',
            removeAddress: 'Remove address',
            editAddress: 'Edit address',
            street: 'Street with Nr.',
            zip: 'Zip Code',
            location: 'Location',
            hint: 'Hint for Address',
            land: 'Country',
            delivery: 'Delivery address',
            billing: 'Billing address',
            corresponds: 'Invoice address corresponds to delivery address',
            invoiceAddress: 'Invoice address',
            addressEmpty: 'Your address book is empty',
        },
        eMailChange: {
            text: 'We will make sure that all mails from Boost are sent to your new mail address.',
            current: 'Current mail address',
            example: 'John.Doe@gmail.com',
            newEmail: 'New mail address',
            confirmNew: 'Confirm new mail address',

        },
        login: {
            emailAddress: 'Mail Address',
            password: 'Password',
            login: 'Log in',
            loginWithGoogle: 'Continue with Google',
            loginWithFacebook: 'Continue with Facebook',
            welcomeBack: 'Welcome back',
            newToSport2000: 'I\'m new',
            forgotPassword: 'Forgot Password?',
            forgotPasswordHeadline: 'Reset password',
            revealPassword: 'Reveal password',
            cartContext: {
                headline: 'To Checkout',
                LinkBackToCart: 'Back to card',
            },
        },
        register: {
            alreadyRegistered: 'I have an account',
            joinSport2000: 'Register now',
            register: 'Register',
            joinUs: 'Join Us',
            genderMale: 'Mr.',
            genderFemale: 'Mrs.',
            firstName: 'First Name*',
            lastName: 'Last Name*',
            tcAgree: 'Yes, I agree to the terms and conditions and privacy policy of Frontastic.',
            tcConfirm: 'By creating your account, you agree to our Terms and Conditions & Privacy policy',
            informAgree: 'Yes, I would like to be informed about trends, campaigns and vouchers by mail.',
            registerNow: 'Register Now',
            createUser: 'Create user account',
            createAccount: 'Register',
            continueAsGuest: 'Continue as guest',
        },
        password: {
            title: 'Change your password',
            change: 'For your security, you can change your password at any time.',
            current: 'Your current password',
            new: 'New password',
            confirmNew: 'Confirm new password',
            old: 'Old password',
        },
        user: {
            name: 'Name:',
            gender: 'Gender:',
        },
        resetPassword: {
            headline: 'Create a new password',
            newPasswordLabel: 'New password',
            newPasswordRepeatLabel: 'Repeat the new password',
            submit: 'Set new password',
        },
    },
    miniCart: {
        color: 'Color:',
        size: 'Size:',
        count: 'Quantity:',
        save: 'Saving',
        total: 'Total ',
        subTotal: '(Total amount (incl. VAT)',
        checkout: 'Checkout',
        cart: 'Shopping Cart',
        close: 'Item added to shopping bag:',
    },
    header: {
        absoluteIconAltTitle: 'ABSOLUTE SPORTS',
        absoluteRunIconAltTitle: 'ABSOLUTE RUN',
        absoluteTeamsportIconAltTitle: 'ABSOLUTE TEAMSPORT',
        sport2000IconAltTitle: 'SPORT 2000',
        myAccount: 'My account',
        wishlist: 'Wishlist',
        storeFinder: 'SPORT 2000 Standorte',
        cart: 'Cart',
        closeView: 'Close view',
        allProduct: 'Total products',
        sortimentProduct: 'assorment',
        showAllProducts: 'Show all products',
        allCategory: 'All',
    },
    storeFinder: {
        searchTitle: 'Storefinder',
        filterBtn: 'Filtern',
        searchBtn: 'Suchen',
        placeHolderInput: 'PLZ, Stadt oder Straße',
        sliderDetailBtn: 'Storeseite',
        routeBtn: 'Route',
        storeDetermine: 'Als meinen Store festlegen',
        services: 'Services',
        distance: 'km',
        today: 'Heute',
        permissionLocation: 'Aufforderung zur Geolokalisierung vom Benutzer verweigert',
    },
    search: {
        searchBox: {
            inputPlaceholderMobile: 'Search',
            iconButtonSubmit: 'Search SPORT 2000',
            buttonSubmit: 'Search',
            buttonClose: 'Cancel search',
            buttonCloseMobile: 'Cancel',
            buttonClear: 'Clear search',
            brandPlaceholder: 'Looking for a brand',
            dropdownCategoryHeadline: 'All Categories',
        },
        searchHits: {
            headline: 'Search results',
            buttonAllResults: 'See all',
            empty: 'No results found. Try another search term.',
            colorVariant: 'color',
            colorVariants: 'colors',
            productHeadline: 'Products',
            blogHeadline: 'Posts',
            brandHeadline: 'Brands',
            storeHeadline: 'Specialty Store',
            suggestHeadline: 'Suggestions',
            linkAll: 'View all',
            categoryMarken: 'Brands',
            categorySuggest: 'Search',
            localStorage: 'Recent Searches',
            suggest: 'Most Wanted',
            faqHeadline: 'FAQ',
            categoryName: 'Category',
            emptyCategory: 'There were no search results in the category',
            emptyCategoryLast: 'found. Instead, all categories were searched.',
            categoryStore: 'Stores',
        },
    },
    secondNav: {
        homepage: 'Homepage',
        new: 'New',
        clothing: 'Clothing',
        shoes: 'Shoes',
        sport: 'Sport',
        accessoires: 'Accessoires',
        sale: 'Sale %',
    },
    mobileNav: {
        back: 'Back',
        locations: 'Locations',
        search: 'Search',
        menu: 'Menu',
        account: 'Log-in',
        cart: 'Cart',
    },
    footer: {
        contact: 'Contact',
        serviceHour: '24 hour service',
        writeUs: 'Write to us',
        emailContact: 'hallo@frontastic.cloud',
        paymentTerms: 'Payment terms',
        company: 'Company',
        aboutUs: 'About us',
        partnerships: 'Partnerships',
        career: 'Career',
        press: 'Press',
        service: 'Service',
        track: 'Track your orders',
        faq: 'FAQ',
        delivery: 'Delivery',
        return: 'Return',
        jobs: 'Jobs',
        tc: 'T&Cs',
        imprint: 'Imprint',
        privacy: 'Privacy',
        deliveryHours: 'Delivery within 24 hours',
        invoice: 'Purchase on invoice',
        freeShipping: 'Free Shipping & Returns',
    },
    cart: {
        uvp: 'UVP',
        color: 'Color',
        size: 'Size',
        article: 'Article',
        myCart: 'My Cart',
        paymentMethods: 'Payment methods',
        totalSum: 'Total sum',
        enterVouchers: 'You can enter voucher codes in the last step of the order process.',
        checkout: 'Checkout',
        subtotal: 'Subtotal',
        shippingCosts: 'Shipping costs',
        gratis: 'gratis',
        totalAmount: 'Total amount',
        inclVat: '(Total amount incl. VAT)',
        discounts: 'Discounts',
        removeDiscount: 'Remove discount',
        couponCode: 'Coupon Code',
        discountPlaceholder: 'Your code here',
        emptyCart: {
            title: 'My cart',
            text: 'Your shopping cart is empty.',
        },
        registerNow: 'Register now',
        remove: 'Remove',
        titleListPayment: 'Payments',
        titleListLink: 'Helps',
        wishList: 'Wishlist',
        empty: 'Your shopping bag is empty.',
        removedItemsTitle: 'Products are no longer available and have been removed:',
        removedItemTitle: 'Product is no longer available and has been removed:',
        reducedItemsTitle: 'Products have exceeded inventory and the quantity has been reduced',
        reducedItemTitle: 'Product has exceeded inventory and the quantity has been reduced',
        headlineNoStore: 'Dispatched by Sport2000',
        headlineStore: 'Shipping via shop: ',
    },
    checkout: {
        checkoutTitleNotLogIn: 'Checkout as a Guest',
        checkoutTitleLoggedIn: 'Checkout as a Customer',
        shipping: 'Shipping',
        payment: 'Payment',
        overview: 'Overivew',
        orderConfirmed: 'Your order was confirmed',
        orderConfirmation: 'We have sent your order confirmation and invoice to:',
        continueShopping: 'Continue shopping',
        wentWrong: 'Something went wrong during payment',
        beenCharged: 'Don\'t worry you haven\'t been charged, but you can always try again',
        yourOrderId: 'Order id: ',
        tryAgain: 'Try again',
        backToShop: 'Back to shop',
        backToCart: 'Back to cart',
        nextPayment: 'Continue',
        nextOverview: 'Chargeable order now',
        placeOrder: 'Place order',
        shippingTo: 'Shipping to',
        shippingAddress: 'Shipping address:',
        shippingInformation: 'Delivery Address:',
        billingDetails: 'Billing details',
        billingInformation: 'Billing Address',
        billingDetailsLabel: 'The delivery address differs from the billing address',
        cartOutOfStock: 'Some products are out of stock, Back to shop',
        noProduct: 'No Product, Back to shop',
        paymentMethod: 'Payment method',
        overviewPage: 'Overview',
        yourOrder: 'Your order',
        orderId: 'Order ID',
        invoice: 'Invoice',
        edit: 'Edit',
        pay: 'Continue',
        shippingMethods: 'Shipping methods',
        freeShipping: 'FREE',
        outOfStock: 'Some products are out of stock',
        agbCheckbox: 'I agree to the privacy policy, terms and conditions and cancellation policy.*',
        editDetails: 'Add',
        cancelDetails: 'Cancel',
        discount: {
            message: 'Do you have a discount code?',
            apply: 'Apply',
        },
        form: {
            firstName: 'First name*',
            lastName: 'Last name*',
            phone: 'Phone number',
            email: 'Mail*',
            address: 'Address*',
            city: 'City*',
            addressAddition: 'Address suffix / Company / c/o*',
            zipCode: 'Zip code*',
            country: 'Country*',
            stateOrProvince: 'State or province*',
            streetNumber: 'House Number.*',
            companyName: 'Company Name',
        },
        informationSubscribeEmail: 'Wir nutzen deine E-Mail-Adresse auch für Produktempfehlungen, die auf deinen Einkäufen basieren. Du kannst dem Empfang der E-Mails jederzeit kostenlos widersprechen. <a target="_blank" href="https://www.sport2000.de/datenschutz" class="underline">Datenschutzerklärung</a>.',
        summaryPolicy: 'Es gelten unsere Allgemeinen Geschäftsbedingungen und die Widerrufsbelehrung. Durch Anklicken des Buttons „JETZT KOSTENPFLICHTIG BESTELLEN“ gibst du eine verbindliche Bestellung ab. Wir nutzen deine E-Mail-Adresse auch für Produktempfehlungen, die auf deinen Einkäufen basieren. DU kannst dem Empfang der E-Mails jederzeit kostenlos widersprechen. <a href="https://www.sport2000.de/datenschutz" target="_blank" class="underline">Datenschutzerklärung</a>.',
    },
    wishlist: {
        title: 'Wishlist',
        no_items: 'The wishlist is still empty.',
        emptyWishlist: 'Your wishlist is empty',
        errorMessage: 'Something when wrong. Try reloading this page or keep browsing.',
        keepBrowsing: 'Keep browsing',
        product: '1 Product',
        products: '{count} Products',
        myWishlist: 'My wishlist',
        addToWishlist: 'Wishlist',
        removeToWishlist: 'Remove from wishlist',
        headlineWishlistDetail: 'Wishlist',
    },
    order: {
        myOrder: 'My Order',
        number: 'Order number:',
        date: 'Order date:',
        paymentMethod: 'Payment method:',
        invoice: 'Invoice',
        cancelItem: 'Cancel item',
        paymentDetails: 'Payment details',
        payee: 'Payee:',
        iban: 'IBAN:',
        bic: 'BIC:',
        bank: 'Bank:',
        paymentReason: 'Reason for payment:',
        total: 'Total',
        returnProducts: 'Return Products',
        lastOrder: 'Last order',
        viewOrder: 'View order',
        itemNum: '8th Item',
        selectMethod: 'Select payment method',
        reviewQuestion: 'How do you like this product?',
        pleaseRate: 'Please rate this product',
        yourReview: 'What would you say about this article?',
        deliveryFeatures: {
            title: 'Express delivery:',
            message: 'Order in the next 6 hours and you\'ll get your delivery tomorrow.',
        },
        yourOrderCaps: 'YOUR ORDER',
        colorOrder: 'Color: ',
        sizeOrder: 'Size: ',
        shippingInformationCaps: 'SHIPPING INFORMATION',
        billingInformationCaps: 'BILLING INFORMATION',
        subtotalOrder: 'Subtotal:',
        shipping: 'Shipping:',
        includesVAT: '(Total amount (incl. VAT)',
        noOrders: 'There are no orders in your account',
        paymentFailed: 'Your selected payment is failed, please select the other',
    },
    product: {
        description: 'Description',
        shipping: 'Shipping',
        reviews: 'Reviews',
        reviewsWithCount: '{count} reviews',
        materialOuterFabric: 'Fabric:',
        color: 'Color',
        colorTotal: 'Total Colors',
        designer: 'Designer',
        commonSize: 'Common size',
        viscose: 'Viscose',
        lacing: 'Lacing',
        lining: 'Lining:',
        moreAboutThisProduct: 'More about this product',
        cut: 'Cut:',
        clasp: 'Clasp:',
        style: 'Style:',
        itemNumber: 'Item number:',
        freeShipping: 'Free shipping',
        returnPolicy100days: 'Eligable for return within 100 days',
        standardDelivery: {
            title: 'Standard delivery:',
            message: 'free of charge 2-4 working days',
        },
        delivery24hs: 'Delivery in 24 hs',
        availableForDelivery: '<b>Available</b> - Delivery 2-4 days',
        freeReturns: 'Free returns',
        list: {
            loadMore: 'Load More',
        },
        quantityLabel: 'Quantity',
        outOfStock: 'Product is unavailable',
        sizeProduct: 'Size Chart',
        sizeManufacture: 'Show manufacturer sizes',
        sizeNormal: 'Show standardized sizes',
        sizeSwipe: 'Scroll right and left to see the entire table.',
    },
    productTeaser: {
        newFlag: 'NEW',
        buttonIntoCart: 'To the product',
        addToCart: 'Add to cart',
        color: 'Color',
        colors: 'Colors',
        addToWishlist: 'Add to wishlist',
        from: 'from',
    },
    filters: {
        total: 'Artikel anzeigen',
        newArrivals: 'New arrivals',
        back: 'Back',
        save: 'Save',
        reset: 'Reset',
        pattern: 'Pattern',
        color: 'Color',
        material: 'Material',
        allBrands: 'All Brands',
        brand: 'Brand',
        search: 'Search',
        filter: 'Filter',
        showResults: 'Show results',
        size: 'Size',
        sort: 'Sort',
        price: 'Price',
        sustainability: 'Sustainability',
        invalidPriceRange: 'Invalid price range',
        filters: 'Filters',
        clear: 'Clear',
        clearAll: 'Clear all',
        apply: 'Apply',
        loadMore: {
            products: 'Show more {num} products',
            articles: 'Show more {num} articles',
        },
        loadProductLeft: 'Show {num} more',
        loadAll: 'Show all products',
        previousLoad: 'Show previous products',
        productsFound: 'Products found',
        sortFilter: {
            lowPrice: 'Lowest price',
            highPrice: 'Highest price',
        },
        variants: {
            attributes: {
                'product-manufacturer': 'Manufacturer',
                color: 'Color',
                att_prod_color_name_sport2000: 'Color',
                size: 'Size',
                att_prod_size_eu: 'Size',
                att_prod_brand: 'Brand',
            },
            price: 'Price',
        },
        noResultHeadline: 'Unfortunately nothing found',
        noResultDesc: 'Try a different search term or check the spelling',
    },
    country: {
        DE: 'Germany',
        AT: 'Austria',
        PL: 'Poland',
        HU: 'Hungary',
        ES: 'Spain',
        PT: 'Portugal',
        CH: 'Switzerland',
        CZ: 'Czech Republic',
        SI: 'Slovenia',
        SK: 'Slovakia',
        GB: 'United Kingdom',
        CA: 'Canada',
        US: 'United states',
    },
    state: {
        US: {
            AL: 'Alabama',
            AK: 'Alaska',
            AZ: 'Arizona',
            AR: 'Arkansas',
            CA: 'California',
            CO: 'Colorado',
            CT: 'Connecticut',
            DE: 'Delaware',
            DC: 'District of Columbia',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            IA: 'Iowa',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            ME: 'Maine',
            MD: 'Maryland',
            MA: 'Massachusetts',
            MI: 'Michigan',
            MN: 'Minnesota',
            MS: 'Mississippi',
            MO: 'Missouri',
            MT: 'Montana',
            NE: 'Nebraska',
            NV: 'Nevada',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NY: 'New York',
            NC: 'North Carolina',
            ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WA: 'Washington',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming',
        },
        CA: {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland and Labrador',
            NT: 'Northwest Territories',
            NS: 'Nova Scotia',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
    },
    deleteItem: 'Delete Item',
    joinNewsletter: 'Join our newsletter',
    exclusiveAccess: 'Sign up for exclusive early access',
    submit: 'Submit',
    submitAgree: "By clicking 'Submit', you agree that we may use your information in accordance with our privacy policy and process the data for a specific purpose.",
    myEmail: 'Yes, here is my mail',
    colorProduct: 'Color:',
    sku: 'Article Number.:',
    sizeEmpty: 'No size chart for this article',
    noSizeChoose: 'Please choose a size',
    quantityProduct: 'Quantity:',
    inCartProduct: 'Add to cart',
    back: 'Back',
    titleBoost: 'Boost theme',
    profile: 'Profile',
    loveIt: 'love-it-alternate',
    reviews: 'Reviews',
    writeReview: 'Write a reivew',
    ratingQuestion: 'This product has reviews. Would you like to review this product?',
    viewMore: 'Continue reading',
    validation: {
        required: 'This field is required',
        email: 'Please enter valid mail address',
        emailMatch: 'Mails don\'t match',
        passwordMatch: 'Passwords don\'t match',
        minLength: 'The password must have at least 8 characters',
        passwordPattern: 'The password must contain at least 7 lower and uppercase characters and 1 number.',
        postalCode: 'The zip code must have 5 numbers',
        phone: 'Numbers from 0-9 and the prefix "+" are allowed',
        fieldRequired: '*Required fields',
    },
    slider: {
        previous: 'Previous',
        next: 'Next',
    },
    detail: {
        description: 'Description',
        material: 'Material & Care',
        review: 'Reviews',
        section4: 'Details',
        section5: 'Suitable for the product',
        youtubeVideo: 'Video',
        overview: 'Characteristics',
        manufactureInformation: 'Manufacturer Information',
        contact: 'Contact',
    },
    sweepstakesForm: {
        salutation: {
            label: 'Salutation*',
            option1: 'Ms.',
            option2: 'Mr.',
            option3: 'Mx.',
        },
        address: {
            firstNameLabel: 'First name*',
            lastNameLabel: 'Last name*',
            emailLabel: 'Mail*',
            streetLabel: 'Street*',
            houseNumberLabel: 'No.*',
            zipLabel: 'ZIP*',
            cityLabel: 'City*',
        },
        checkbox: {
            newsletterLabel: 'Yes, I would like to receive the newsletter and be informed about news and competitions.',
            tosLabel: 'I have read and agree to the <a href="/datenschutz">Privacy Policy</a>.',
        },
        submitLabel: 'Submit',
    },
    contactForm: {
        salutation: {
            label: 'Salutation',
            option1: 'Ms.',
            option2: 'Mr.',
            option3: 'Mx.',
        },
        address: {
            firstNameLabel: 'First name',
            lastNameLabel: 'Last name',
            emailLabel: 'Mail*',
        },
        message: {
            subjectLabel: 'Subject*',
            messageLabel: 'Message*',
        },
        checkbox: {
            newsletterLabel: 'Yes, I would like to receive the newsletter and be informed about news and competitions.',
            tosLabel: 'I have read and agree to the <a href="/agb">Terms and Conditions</a> and <a href="/datenschutz">Privacy Policy</a>.',
            subsribeLabel: 'Ich möchte über aktuelle Aktionen, Neuigkeiten und Gewinnspiele per E-Mail informiert werden und weiß, dass ich mich jederzeit kostenlos abmelden kann. <a href="/datenschutz">Mehr zum Datenschutz</a>.',
        },
        submitLabel: 'Submit',
        successMsg: 'Thank you for your message, we will contact you shortly.',
        errorMsg: 'Unfortunately an error has occurred, please try again. If the error still occurs, please contact our customer service directly by email: service@sport2000.de.',
    },
    home: {
        backToShop: 'Back to shop',
    },
    productDetail: {
        badge: 'New',
        tax: 'incl. VAT and plus <a href="/service/versand" class="underline">shipping</a>',
        colorLabel: 'Color:',
        sizeLabel: 'Size:',
    },
    common: {
        requiredInput: '* Required field',
    },
    blog: {
        showDetail: 'Read More',
        moreBlogHeadline: 'More Blogs Post',
    },
    breadCrumb: {
        home: 'Home',
        back: 'Back',
    },
    payment: {
        creditCard: 'Credit Card',
        paypal: 'PayPal',
        sofort: 'Sofort.',
        klarna: 'Invoice with Klarna',
    },
    brandDetail: {
        headlineProductList: 'Products ',
        showMore: 'Show more',
        showLess: 'Show less',
        button: 'Read more',
    },
    mailjet: {
        headline: 'Stay tuned!',
        description: 'Dont miss any more trends and offers with our newsletter!',
        subscribe: 'Newsletter',
        title: 'What are you interested in?',
        cancel: 'Cancel',
        submit: 'Sign up now',
        confirmHeadline: 'Subscribed to newsletter',
        confirmDescription: 'Thank you for subscribing to our newsletter. Please check your mailbox and confirm the registration.',
        confirmButton: 'Close',
        checkAll: 'Check all',
        subscribeButton: 'Subscribe',
        errorDescription: 'Sorry, we are having trouble with our server',
        errorHeadline: 'Error newsletter',
        checkPrivacy: 'I agree to receive this newsletter and know that I can easily unsubscribe at any time. <a href="/datenschutz" class="underline">More about data protection</a>',
    },
    flyout: {
        marketingBannerLink: 'Read more',
    },
    storeDetail: {
        telephone: 'Tel.:',
        serviceHeadline: 'Local service',
        paymentHeadline: 'Payment methods on site',
        brandHeadline: 'Our local brands',
        teamHeadline: 'The Team',
        sportHeadline: 'Our sports',
        productHeadline: 'Our Products',
        specialLogoHeadline: 'Member of',
        openingHeadline: 'Opening hours',
        serviceSeeMore: 'Show all services',
        serviceSeeLess: 'Hide all services',
        product: 'Products',
        information: 'About the store',
        marken: 'Brands',
        headerStoreLink: 'Go to Storefinder',
        headerStoreHeadline: 'My select store',
        headerMyStore: 'My store',
        headerButtonLink: 'Go to Storefinder',
        selectStoreOption: 'Set as my store',
        routeMobile: 'Route',
        routeDesktop: 'Show route',
        telephoneMobile: 'Phone',
        email: 'Email',
        specialLogoLink: 'See more',
        headerStoreDataHeadline: 'My Store',
        showMore: 'Show all sports',
        showLess: 'Show fewer sports',
        rating: 'Reviews',
        ratingValue: '/ 5',
        ratingDescription: 'Stars',
        summaryRating: 'We are interested in how satisfied you are! That\'s why we use Qualitize. Immediately after your purchase in the local store, you can easily rate us using a touchpad. This allows us to collect direct feedback every day. This allows us to constantly develop our services for you.',
        in: 'in ',
    },
    categorySidebar: {
        dropdownTitle: 'Kategorie ändern',
        close: 'close',
        menuTitle: 'Kategorien',
    },
    topBar: {
        expertArea: 'Gehe zu <b>SPORT 2000</b>',
    },
    newsletterContact: {
        headline: 'Please select your interests',
        newsRequired: 'Please select one of the options to continue',
        sportRequired: 'Please select one of the sports to continue',
    },
}
