import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

export type VendorConsent = Record<string, boolean>;
export const CONSENT_VENDOR_GOOGLE_MAP_ID = 's1104';
export const CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID = 's905';
const optionalVendorConsents = [CONSENT_VENDOR_GOOGLE_MAP_ID, CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID];

const useConsentManagement = (): { cmpData: VendorConsent; isGtmAccepted: boolean, vendorConsents: string[] } => {
  const [cmpData, setCmpData] = useState<VendorConsent>(null);
  const [isGtmAccepted, setIsGtmAccepted] = useState<boolean>(false);

  useEffect(() => {
    if (cmpData) {
      setIsGtmAccepted(cmpData[CONSENT_VENDOR_GOOGLE_TAG_MANAGER_ID]);
    }
  }, [cmpData]);

  useEffect(() => {
    const POLLING_INTERVAL = 100;
    const MAX_POLLING_ATTEMPTS = 50;
    let pollingAttempts = 0;
    let timeoutId: NodeJS.Timeout;

    const fetchCmpData = () => {
      if (typeof (window as any).__cmp === 'function') {
        const cmpDataFetched = (window as any).__cmp('getCMPData');
        if (isEmpty(cmpDataFetched?.vendorConsents)) {
          pollingAttempts += 1;
          if (pollingAttempts < MAX_POLLING_ATTEMPTS) {
            timeoutId = setTimeout(fetchCmpData, POLLING_INTERVAL);
          }
          return;
        }

        const vendorConsents = optionalVendorConsents.reduce((acc, vendorId) => {
          acc[vendorId] = cmpDataFetched.vendorConsents[vendorId] || false;
          return acc;
        }, {} as VendorConsent);

        setCmpData(vendorConsents);
      } else {
        pollingAttempts += 1;
        if (pollingAttempts < MAX_POLLING_ATTEMPTS) {
          timeoutId = setTimeout(fetchCmpData, POLLING_INTERVAL);
        }
      }
    };

    fetchCmpData();
    if (typeof (window as any).__cmp === 'function') {
      (window as any).__cmp('addEventListener', ['consent', fetchCmpData, false], null);
    }

    return () => {
      clearTimeout(timeoutId);
      if (typeof (window as any).__cmp === 'function') {
        (window as any).__cmp('removeEventListener', ['consent', fetchCmpData, false], null);
      }
    };
  }, []);

  return { cmpData, isGtmAccepted, vendorConsents: cmpData ? Object.keys(cmpData).filter(key => cmpData[key]) : [] };
};

export default useConsentManagement;
