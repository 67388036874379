import UrlContext from '@frontastic/catwalk/js/app/urlContext';
import { NextRouter } from 'next/router';
import { AbortSignal } from 'node-fetch/externals';
import { rawFetchApiHub } from '../../../../frontastic';
import { FrontasticStore } from '../../../redux/store';
import Router from './router';

type Parameters = object & {
  hasError?: boolean;
  ownErrorHandler?: boolean;
  [key: string]: string | boolean | number;
};

type Body = any;
type SuccessCallback = (result: any, parameters: Parameters) => void;
type ErrorCallback = (error: any) => void;

interface RefreshInterval {
  refreshFunction: () => void;
  refreshInterval: number;
  interval: number;
}

class Api {
  public router: Router;
  private store: FrontasticStore;

  private intervals: RefreshInterval[] = [];

  constructor(router: Router, store: FrontasticStore) {
    this.router = router;
    this.store = store;
  }

  async request(
    method: string,
    route: string,
    parameters: Parameters = {},
    body: Body = undefined,
    successCallback: SuccessCallback = undefined,
    errorCallback: ErrorCallback = undefined,
    // @COFIXME[not-implemented](FLBML-140): Actually use the signal
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signal: AbortSignal = undefined,
  ): Promise<any> {
    try {
      const response = await rawFetchApiHub(
        `/action/legacy-data/api-proxy`,
        {
          method: 'POST',
        },
        {
          route: {
            id: route,
            parameters,
          },
          method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: this.getBody(body),
        },
      );

      // @COFIXME[not-implemented](FLBML-141): Add the injectable App.ResponseHandler here

      this.handleFrontasticRedirect(response);

      const contentType = response.headers.get('Content-Type');
      if (contentType === null || !contentType.includes('application/json')) {
        console.error('Unhandled Response Type:', response);
        throw { status: response?.status, message: 'Internal Server Error' };
      }

      const json = await response.json();

      if (!response.ok) {
        if (typeof json.message === 'string') {
          console.error('Error:', json.message);
          throw { status: response?.status, message: json.message };
        }

        console.error('Unhandled Error:', response);
        throw { status: response?.status, message: 'Internal Server Error' };
      }

      if (successCallback) {
        successCallback(json, parameters);
      }

      return json;
    } catch (error) {
      if (errorCallback) {
        errorCallback(error);
      }

      if (!parameters?.hasError && !parameters?.ownErrorHandler) {
        // @COFIXME[not-implemented](FLBML-142): Handle error
      } else if (!errorCallback) {
        throw error;
      }
    }
  }

  requestContinuosly(
    method: string,
    route: string,
    parameters: Parameters = {},
    successCallback: SuccessCallback = undefined,
    errorCallback: ErrorCallback = undefined,
  ): void {
    const refreshInterval = this.getReloadInterval();
    const refreshFunction = () => {
      this.request(method, route, parameters, null, successCallback, errorCallback);
    };

    refreshFunction();
    this.intervals.push({
      refreshFunction,
      refreshInterval,
      interval: window.setInterval(refreshFunction, refreshInterval),
    });
  }

  async trigger(route: string, parameters: Parameters = {}, actionId: string = null) {
    const routeKey = this.getTriggerRouteKey(route);

    return this.request(
      'GET',
      route,
      parameters,
      null,
      this.getTriggerSuccessFunction(routeKey, actionId, parameters),
      this.getTriggerErrorFunction(routeKey, actionId, parameters),
    );
  }

  triggerContinuously(route: string, parameters: Parameters = {}, actionId: string = null): void {
    const routeKey = this.getTriggerRouteKey(route);

    this.requestContinuosly(
      'GET',
      route,
      parameters,
      this.getTriggerSuccessFunction(routeKey, actionId, parameters),
      this.getTriggerErrorFunction(routeKey, actionId, parameters),
    );
  }

  clearContinuousRequests() {
    this.pauseRequests();
    this.intervals = [];
  }

  pauseRequests() {
    if (!this.intervals) {
      return;
    }

    for (let i = 0; i < this.intervals.length; ++i) {
      window.clearInterval(this.intervals[i].interval);
      this.intervals[i].interval = null;
    }
  }

  resumeRequests() {
    if (!this.intervals) {
      return;
    }

    for (let i = 0; i < this.intervals.length; ++i) {
      this.intervals[i].interval = window.setInterval(
        this.intervals[i].refreshFunction,
        this.intervals[i].refreshInterval,
      );
    }
  }

  private getTriggerRouteKey(route: string) {
    return route.substring(route.indexOf('.') + 1);
  }

  private getTriggerSuccessFunction(routeKey: string, actionId: string, parameters: Parameters): SuccessCallback {
    return (data) => {
      this.store.dispatch({
        type: routeKey + '.success',
        id: actionId,
        cacheKey: UrlContext.getActionHash(parameters),
        data,
        parameters,
      });
    };
  }

  private getTriggerErrorFunction(routeKey: string, actionId: string, parameters: Parameters): ErrorCallback {
    return (error) => {
      this.store.dispatch({
        type: routeKey + '.error',
        id: actionId,
        cacheKey: UrlContext.getActionHash(parameters),
        error,
        parameters,
      });
    };
  }

  private getBody(body: Body): string | undefined {
    if (body === null || body === undefined) {
      return undefined;
    }

    if (typeof body === 'string' || body instanceof String) {
      return body.toString();
    }

    return JSON.stringify(body);
  }

  private handleFrontasticRedirect(response: Response) {
    if (response.headers.has('Frontastic-Location')) {
      this.router.nextRouter.push(response.headers.get('Frontastic-Location'));
    }
  }

  private getReloadInterval() {
    return 5 * 60 * 1000;
  }
}

export default Api;
